/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import * as hostService from 'services/host/HostService';

declare module 'axios' {
  export interface AxiosError {
    hasError?: boolean;
  }
}
export interface IRequest {
  url: string;
  body?: any;
  config?: any;
}

export const initialAxios = Axios.create({ baseURL: document.location.origin });

const authentication: any = useAuthenticationService();

const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

authentication.enhancedAxios(initialAxios, {
  headers: [
    {
      country: userCountry || '',
      'Accept-Language': 'en-US',
    },
  ],
});

const getUrl = (url: string, country?: string) => `${hostService.getApiHost(country || userCountry)}${url}`;

const catchFn = (error: any) => ({ hasError: true, ...error });

const Api = {
  get: ({ url }: IRequest): Promise<any> => initialAxios.get(getUrl(url)).catch(catchFn),

  post: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.post(getUrl(url, config?.headers?.country), body, config).catch(catchFn),

  delete: ({ url }: IRequest): Promise<any> => initialAxios.delete(getUrl(url)).catch(catchFn),

  put: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.put(getUrl(url, config?.headers?.country), body, config).catch(catchFn),

  patch: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.patch(getUrl(url), body).catch(catchFn),
};

export default Api;
